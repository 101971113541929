'use client';
import { Breakpoint, createTheme } from '@mui/material/styles';
import { breakpoints } from '@bayada/assets';
import { palette } from '@bayada/assets';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    base: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
    fhd: true;
    qhd: true;
    kkk: true;
    uwqhd: true;
    kkkk: true;
  }
}

const styleBreakPoints: { [key in Breakpoint]: number } = {
  base: 0,
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
  xxl: 0,
  xxxl: 0,
  fhd: 0,
  qhd: 0,
  kkk: 0,
  uwqhd: 0,
  kkkk: 0
};

if (breakpoints) {
  for (const key in breakpoints) {
    const breakPoint = key as Breakpoint;
    styleBreakPoints[breakPoint] = Number(breakpoints[key]);
  }
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: palette ? palette['ba-primary-shade'] : '#CE0E2D'
    },
    secondary: {
      main: palette ? palette['ba-primary-black'] : '#232323'
    }
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          minHeight: '44px !important',
          '&.bg-white': {
            background: 'var(--white)'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&.MuiAppBar-colorPrimary': {
            color: 'var(--ba-primary-black)'
          }
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false
      },
      styleOverrides: {
        root: {
          ...(Object.keys(breakpoints) as Breakpoint[]).reduce(
            (acc, breakpoint) => {
              const minWidth = breakpoints[breakpoint];

              if (
                breakpoint === 'base' ||
                breakpoint === 'xs' ||
                breakpoint === 'sm' ||
                breakpoint === 'md'
              ) {
                acc[`@media (max-width: ${minWidth}px)`] = {
                  paddingLeft: `16px`,
                  paddingRight: `16px`
                };
              } else if (breakpoint === 'fhd' || breakpoint === 'qhd') {
                acc[`@media (min-width: ${minWidth}px)`] = {
                  paddingLeft: `48px`,
                  paddingRight: `48px`
                };
                acc[`@media (min-width: calc(${minWidth}px + 100px))`] = {
                  paddingLeft: `24px`,
                  paddingRight: `24px`
                };
              } else if (
                breakpoint === 'kkk' ||
                breakpoint === 'uwqhd' ||
                breakpoint === 'kkkk'
              ) {
                acc[`@media (min-width: ${minWidth}px)`] = {
                  paddingLeft: `64px`,
                  paddingRight: `64px`
                };
                acc[`@media (min-width: calc(${minWidth}px + 100px))`] = {
                  paddingLeft: `24px`,
                  paddingRight: `24px`
                };
              } else {
                acc[`@media (min-width: ${minWidth}px)`] = {
                  paddingLeft: `32px`,
                  paddingRight: `32px`
                };
                acc[`@media (min-width: calc(${minWidth}px + 100px))`] = {
                  paddingLeft: `24px`,
                  paddingRight: `24px`
                };
              }

              return acc;
            },
            {} as { [key: string]: React.CSSProperties }
          )
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      variants: [
        {
          props: {
            variant: 'contained',
            color: 'primary'
          },
          style: ({ theme }) => ({
            [theme.breakpoints.down('xs')]: {
              '&.MuiButton-contained, &.MuiButton-outlined': {
                width: '100%' // Set width to 100% below xs breakpoint
              }
            },
            transition: 'background 0.3s ease-in-out',
            willChange: 'background',
            background: `linear-gradient(
                96deg,
                hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 0%,
                hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 19.27%,
                hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 66.15%,
                hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 100%
               )`,
            '&:hover': {
              background: `linear-gradient(
                96deg,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 0%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 3%)) 19.27%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 5%)) 66.15%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 100%
              )`
            },
            '&:active': {
              background: `linear-gradient(
                96deg,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 0%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 3%)) 19.27%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 5%)) 66.15%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 100%
              )`
            },
            fontFamily: 'var(--font-secondary)',

            '&:focus': {
              background: palette ? palette['ba-btn-focus-bg'] : '#AE0420'
            },
            textTransform: 'none',
            padding: '0.813rem 1.5rem'
          })
        },
        {
          props: { variant: 'contained', color: 'primary', disabled: true },
          style: ({ theme }) => ({
            [theme.breakpoints.down('xs')]: {
              '&.MuiButton-contained, &.MuiButton-outlined': {
                width: '100%' // Set width to 100% below xs breakpoint
              }
            },
            transition:
              'background 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            willChange: 'background, border, color, box-shadow',
            background: palette ? palette['ba-btn-disabled'] : '#C7C7C7',
            textTransform: 'none',
            padding: '0.813rem 1.5rem',
            pointerEvents: 'none',
            fontFamily: 'var(--font-secondary)'
          })
        },
        {
          props: {
            variant: 'contained',
            color: 'secondary'
          },
          style: ({ theme }) => ({
            [theme.breakpoints.down('xs')]: {
              '&.MuiButton-contained, &.MuiButton-outlined': {
                width: '100%' // Set width to 100% below xs breakpoint
              }
            },
            transition:
              'background 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            willChange: 'background, border, color, box-shadow',
            textTransform: 'none',
            padding: '0.813rem 1.5rem',
            fontFamily: 'var(--font-secondary)',
            '&:hover': {
              background: palette ? palette['ba-primary-black'] : '#232323',
              color: 'var(--white)'
            }
          })
        },
        {
          props: {
            variant: 'outlined',
            color: 'secondary'
          },
          style: ({ theme }) => ({
            [theme.breakpoints.down('xs')]: {
              '&.MuiButton-contained, &.MuiButton-outlined': {
                width: '100%' // Set width to 100% below xs breakpoint
              }
            },
            textTransform: 'none',
            padding: '0.813rem 1.5rem',
            transition:
              'background 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            willChange: 'background, border, color, box-shadow',
            background: 'var(--white)',
            fontFamily: 'var(--font-secondary)',
            '&:hover': {
              color: 'var(--white)',
              background: palette ? palette['ba-primary-black'] : '#232323'
            },
            '&:focus': {
              background: 'var(--black)',
              color: 'var(--white)'
            }
          })
        },
        {
          props: {
            variant: 'text',
            color: 'primary'
          },
          style: {
            textTransform: 'none',
            color: 'var(--primary-shade)',
            fontFamily: 'var(--font-secondary)',
            '&:hover': {
              color: 'var(--ba-primary-black)'
            },
            '&:focus': {
              color: 'var(--ba-primary-black)',
              outline: 'solid 2px var(--ba-primary-black)'
            }
          }
        },
        {
          props: {
            variant: 'text',
            color: 'secondary'
          },
          style: {
            textTransform: 'none',
            color: 'var(--primary-shade)',
            fontFamily: 'var(--font-secondary)',
            '&:hover': {
              color: 'var(--ba-primary-black)'
            },
            '&:focus': {
              color: 'var(--ba-primary-black)',
              outline: 'solid 2px var(--ba-primary-black)'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          transform: 'scale(1)',
          willChange: 'transform, background, background-color, color',
          transition: 'transform 0.1s ease',
          '&.bg-white': {
            background: 'var(--white)'
          },
          '&.hero-search': {
            padding: '16px'
          },
          '&.nav-dropdown-trigger': {
            textTransform: 'capitalize',
            lineHeight: 1
          },
          '&.nav-logo-button': {
            minWidth: '121px',
            minHeight: '38px',
            '&:focus': {
              outline: 'dashed 1px var(--white)'
            }
          },
          '&.button-small': {
            padding: '8px 20px',
            minHeight: '40px'
          },
          '&.menu-dropdown': {
            padding: '6px 8px',
            color: 'inherit',
            transition: 'none',
            '.MuiButton-endIcon': {
              position: 'relative',
              top: '0px',
              marginRight: '0px'
            },
            '&:focus-visible': {
              outline: 'solid 2px var(--black)'
            },
            '  &:focus:not(:focus-visible)': {
              outline: 'none'
            },
            '&:hover': {
              background: 'rgba(35, 35, 35, 0.9)',
              color: 'var(--white)'
            },
            '&[aria-expanded="true"]': {
              backgroundColor: 'var(--black)',
              color: 'var(--white)',
              '.MuiButton-endIcon': {
                transform: 'rotate(180deg)'
              }
            },
            span: {
              position: 'relative',
              zIndex: 1
            }
          },
          '&.icon-only': {
            '.MuiButton-endIcon': {
              margin: '0px'
            },
            '.MuiButton-startIcon': {
              margin: '0px'
            }
          },
          '&:active': {
            transform: 'scale(1.01)'
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            transition: 'background 0.3s ease-in-out',
            willChange: 'background',
            background: `linear-gradient(
              96deg,
              hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 0%,
              hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 19.27%,
              hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 66.15%,
              hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 100%
             )`,
            fontFamily: 'var(--font-secondary)',
            '&:hover': {
              background: `linear-gradient(
                96deg,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 0%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 3%)) 19.27%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 5%)) 66.15%,
                hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 100%
              )`
            },
            '&:focus': {
              background: palette ? palette['ba-btn-focus-bg'] : '#AE0420'
            },
            textTransform: 'none',
            padding: '0.813rem 1.5rem'
          }
        }
      ],
      styleOverrides: {
        root: {
          '&.rounded-stepper-control': {
            borderRadius: '50%',
            width: '48px',
            height: '48px',
            border: '1px solid var(--black)',
            color: 'var(--black)',
            '&[disabled]': {
              background: 'var(--ba-gray-200)'
            },
            '&:focus': {
              outline: 'none !important',
              background: palette ? palette['ba-primary-black'] : '#232323',
              color: 'var(--white)'
            },
            '&:hover': {
              background: palette ? palette['ba-primary-black'] : '#232323',
              color: 'var(--white)'
            },
            '&:focus-visible': {
              outline: '1px solid var(--black) !important'
            }
          }
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled', disabled: true },
          style: {
            backgroundColor: 'transparent',
            width: '100%'
          }
        }
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            backgroundColor: palette ? palette['ba-field-hover-bg'] : '#F3F3F3'
          },
          '&:focus': {
            backgroundColor: palette ? palette['ba-field-focus-bg'] : '#EAEAEA'
          },
          backgroundColor: 'var(--white)',
          underline: {
            '&:hover:not($disabled):before': {
              borderBottom: 'none' // Remove the bottom border on hover
            }
          },
          '.MuiFilledInput-root': {
            fontSize: 'var(--t-16-17)',
            [theme.breakpoints.up('lg')]: {
              fontSize: 'var(--t-15)'
            }
          },
          '&.app-input-field': {
            color: 'var(--ba-primary-black)',
            fontSize: 15,
            fontWeight: 500,
            lineHeight: '24px',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '.MuiFilledInput-root': {
              padding: '14px 0 10px 4px',
              borderRadius: '4px',
              fontSize: 15,
              fontWeight: 500,
              lineHeight: '24px',
              backgroundColor: 'var(--ba-gray-100)',
              '&:hover': {
                backgroundColor: palette
                  ? palette['ba-field-focus-bg']
                  : '#EAEAEA'
              },
              '&.Mui-focused': {
                padding: '14px 0 10px 4px',
                outline: '2px solid var(--ba-blue-200)',
                background: 'var(--ba-gray-100)'
              },
              '&.Mui-error': {
                color: 'var(--primary-shade)'
              }
            },
            '.MuiInputBase-inputMultiline': {
              marginTop: '9px',
              paddingLeft: '12px',
              paddingRight: '12px'
            },
            '.MuiFilledInput-underline': {
              '&:before,&:after': {
                display: 'none'
              }
            },
            '.MuiInput-root': {
              '&:before,&:after': {
                display: 'none'
              }
            }
          },
          '&.app-icon-field': {
            backgroundColor: 'transparent',
            color: 'var(--ba-primary-black)',
            fontSize: 15,
            fontWeight: 500,
            lineHeight: '24px',
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '.MuiFilledInput-underline, .MuiInput-root': {
              '&:before, &:after': {
                display: 'none'
              }
            }
          },
          '&.app-search-field': {
            backgroundColor: 'transparent',
            color: 'var(--ba-primary-black)',
            fontSize: 'var(--t-15)',
            fontWeight: 500,
            lineHeight: '24px',
            minHeight: '58px',
            width: '100%',
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '.MuiInputLabel-root': {
              fontSize: 'var(--t-15)',
              '&.Mui-focused': {
                color: 'var(--ba-gray-425)'
              }
            },
            '.MuiInputLabel-root ~.MuiFilledInput-root': {
              '.MuiFilledInput-input': {
                paddingTop: '25px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }
            },
            '.MuiFilledInput-root': {
              minHeight: '58px',
              width: '100%',

              color: 'var(--ba-primary-black)',
              fontWeight: 500,
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                outline: '2px solid var(--ba-blue-200)',
                borderRadius: '5px'
              },
              '.MuiFilledInput-input': {
                minHeight: '58px',
                padding: '0',
                paddingLeft: '10px',
                paddingRight: '10px'
              }
            },
            '.MuiFilledInput-underline, .MuiInput-root': {
              '&:before, &:after': {
                display: 'none'
              }
            },
            '&.dark-field': {
              '.MuiFilledInput-root': {
                backgroundColor: 'var(--ba-gray-100)'
              }
            }
          }
        })
      }
    },
    MuiFormControl: {
      variants: [
        {
          props: {
            variant: 'standard',
            color: 'primary'
          },
          style: {
            '&.app-form-control': {
              '.MuiFormLabel-filled': {
                left: 16,
                top: 12
              },
              '.MuiFormLabel-focused': {
                left: 16,
                top: 12
              },
              '.app-form-select': {
                backgroundColor: 'var(--white)',
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
                '&:hover': {
                  backgroundColor: 'var(--white)',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)'
                },
                '&:focus': {
                  outline: '2px solid var(--ba-blue-200)'
                },
                '&.Mui-focused': {
                  backgroundColor: 'var(--white)'
                }
              },
              '&.find-care': {
                '.MuiFormLabel-root': {
                  // top: '-5px',
                  left: '12px'
                }
              }
            }
          }
        },
        {
          props: {
            variant: 'filled',
            color: 'primary'
          },
          style: {
            '&.app-form-control': {
              '.select-input-label': {
                left: 4,
                top: 1
              },

              '.MuiFormLabel-filled': {
                left: 5,
                top: 3
              },

              '.app-form-select': {
                '.MuiFilledInput-input': {
                  padding: '4px 0 5px'
                }
              }
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          '&.app-search-form-control': {
            width: 'clamp(40%, 100px, 100%)'
          },
          '&.app-form-control': {
            width: '100%',
            backgroundColor: 'var(--ba-gray-100)',
            minHeight: 58,
            transition: 'backgroundColor 0.3s ease-in-out',
            willChange: 'backgroundColor',
            '&:hover': {
              backgroundColor: palette
                ? palette['ba-field-focus-bg']
                : '#EAEAEA'
            },
            '&:focus': {
              background: 'var(--ba-gray-100)',
              outline: '2px solid var(--ba-blue-200)'
            },
            '&.app-form-outline': {
              borderRadius: '8px',
              minHeight: '40px',
              border: '1px solid var(--ba-primary-black)',
              backgroundColor: 'var(--white)',
              '&:hover, &:focus': {
                backgroundColor: 'var(--ba-primary-black)',
                color: 'var(--white)',
                outline: 'none'
              },
              '&:hover >.label-outline': {
                color: 'var(--white)'
              },
              '.app-form-select': {
                borderRadius: '8px',
                paddingTop: '20px',
                backgroundColor: 'transparent',
                '&.Mui-focused': {
                  backgroundColor: 'var(--ba-primary-black)',
                  outline: 'none',
                  '.MuiBox-root': {
                    '.svg-icon': {
                      color: 'var(--white)!important'
                    }
                  }
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'var(--white)'
                },
                '.MuiSelect-select': {
                  padding: '0'
                }
              }
            },
            '&.find-care': {
              '.app-form-select': {
                boxShadow: 'none',
                padding: 0,
                '.MuiSelect-select': {
                  minHeight: 58,
                  cursor: 'pointer',
                  paddingLeft: '0.688rem',
                  paddingTop: '1.85rem'
                },
                '.MuiBox-root': {
                  cursor: 'pointer',
                  pointerEvents: 'none'
                }
              }
            }
          },
          '&.app-input-field-control': {
            borderRadius: '4px',
            minHeight: 58,
            width: '100%',
            '.MuiInputLabel-root': {
              fontSize: 15,
              fontWeight: '500',
              lineHeight: '24px',
              color: 'var(--ba-primary-black)',
              left: 4,
              '&.Mui-focused': {
                color: 'var(--ba-gray-425)'
              },
              '&.MuiFormLabel-filled': {
                color: 'var(--ba-gray-425)'
              }
            },
            '.MuiInput-root': {
              fontSize: '15px',
              fontWeight: 500,
              lineHeight: '24px'
            },
            '&.standardInputField': {
              '.MuiInputLabel-standard': {
                left: 16,
                top: '-4px',
                zIndex: 2,
                '&.Mui-focused': {
                  left: 16,
                  top: 10
                },
                '&.MuiFormLabel-filled': {
                  left: 16,
                  top: 10
                }
              },
              '.MuiInput-root': {
                padding: '29px 16px 7px 16px',
                margin: 0,
                borderRadius: '4px',
                backgroundColor: 'var(--white)',
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
                '&:hover': {
                  backgroundColor: 'var(--white)',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)'
                },
                '&.Mui-focused': {
                  outline: '2px solid var(--ba-blue-200)'
                },
                '&.Mui-error': {
                  color: 'var(--primary-shade)'
                }
              }
            },
            '&.plainInputField': {
              minHeight: 'auto',
              '.MuiInputLabel-standard': {
                zIndex: 2,
                top: '-10px',
                left: 0,
                '&.Mui-focused': {
                  top: '0px'
                },
                '&.MuiFormLabel-filled': {
                  top: '0px'
                }
              },
              '.MuiInput-root': {
                backgroundColor: 'var(--white)',
                marginTop: 20
              },
              '.MuiFormHelperText-root': {
                bottom: '-40px',
                marginLeft: 0
              }
            },
            '.MuiFormHelperText-root': {
              fontSize: 13,
              fontWeight: '500',
              lineHeight: '18px',
              marginTop: 8,
              marginLeft: 16,
              transform: 'translate(0px, -8px)',
              transition: 'transform ease 0.3s',
              willChange: 'transform',
              opacity: 0,
              position: 'absolute',
              bottom: '-22px',
              '&.MuiFormHelperText-filled': {
                transform: 'translate(0, 0)',
                opacity: 1
              }
            }
          },
          '&.app-dropdown-wrapper': {
            position: 'relative',
            '.app-dropdown-button': {
              justifyContent: 'flex-start',
              color: 'var(--ba-primary-black)',
              fontSize: 15,
              fontWeight: '400',
              lineHeight: '24px',
              display: 'flex',
              alignItems: 'center',
              gap: 12,
              padding: '10px 16px',
              borderRadius: 0,
              backgroundColor: 'var(--white)'
            },
            '.app-nav-dropdown-button': {
              justifyContent: 'flex-start',
              color: 'var(--ba-primary-black)',
              fontSize: 15,
              fontWeight: '400',
              lineHeight: '24px',
              display: 'flex',
              alignItems: 'center',
              gap: 12,
              padding: '8px 16px',
              borderRadius: 0,
              backgroundColor: 'var(--white)'
            }
          },
          '&.app-country-select': {
            width: '100%',
            '.MuiInputBase-root': {
              minHeight: 58,
              padding: '26px 14px 4px 14px',
              borderRadius: 4,
              fontSize: 'var(--t-15)',
              fontWeight: '500',
              '&.Mui-focused': {
                backgroundColor: 'var(--ba-gray-100)',
                outline: '2px solid var(--ba-blue-200)'
              },
              '&.sd': {
                padding: '14px 0 10px 4px'
              },
              '.MuiAutocomplete-input': {
                padding: '0 0px'
              },
              '.MuiAutocomplete-endAdornment': {
                top: 'calc(50% - 17px)'
              }
            },
            '.MuiInputLabel-root': {
              fontSize: 'var(--t-14-15)',
              fontWeight: '500',
              lineHeight: '18px',
              color: 'var(--ba-primary-black)',
              top: 5,
              '&.Mui-focused': {
                color: 'var(--ba-gray-425)',
                top: 18
              },
              '&.MuiFormLabel-filled': {
                color: 'var(--ba-gray-425)',
                top: 18
              }
            }
          },
          '&.icon-text-field': {
            borderRadius: '4px',
            minHeight: '58px',
            flexDirection: 'row',
            width: '100%',
            boxShadow:
              '0px 4px 10px 0px rgba(0, 0, 0, 0.14), 0px 0px 4px 0px rgba(0, 0, 0, 0.12)',
            '&.icon-right': {
              left: '0px',
              '&.standardInputField': {
                '.MuiInputLabel-standard': {
                  left: '16px'
                },
                '.MuiInput-root': {
                  padding: '8px 50px 8px 16px'
                }
              }
            },
            '&.standardInputField': {
              '.MuiInputLabel-standard': {
                left: '49px',
                top: '-4px',
                zIndex: 2,
                '&.Mui-focused': {
                  display: 'none'
                },
                '&.MuiFormLabel-filled': {
                  display: 'none'
                }
              },
              '.MuiInput-root': {
                padding: '8px 16px 8px 50px',
                height: '100%',
                minHeight: '58px',
                margin: 0,
                borderRadius: '4px',
                backgroundColor: 'var(--white)',
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
                '&:hover': {
                  backgroundColor: 'var(--white)',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)'
                },
                '&.Mui-focused': {
                  outline: '2px solid var(--ba-blue-200)'
                },
                '&.Mui-error': {
                  color: 'var(--primary-shade)'
                },
                '.MuiInputBase-input': {
                  outline: 'none !important'
                }
              }
            },
            '.MuiInputLabel-standard': {
              color: 'var(--ba-gray-425)'
            },
            '.app-icon-field': {
              flex: 'grow',
              height: '100%',
              minHeight: '58px',
              width: '100%'
            }
          },
          '&.search-icon-text-field': {
            borderRadius: '4px',
            minHeight: '58px',
            flexDirection: 'row',
            width: '100%',
            boxShadow:
              '0px 4px 10px 0px rgba(0, 0, 0, 0.14), 0px 0px 4px 0px rgba(0, 0, 0, 0.12)',
            backgroundColor: 'var(--ba-gray-100)',
            '&.icon-right': {
              left: '0px',
              '&.standardInputField': {
                '.MuiInputLabel-standard': {
                  left: '16px'
                },
                '.MuiInput-root': {
                  padding: '8px 20px 8px 16px'
                }
              }
            },
            '&.standardInputField': {
              '.MuiInputLabel-standard': {
                left: '49px',
                top: '-4px',
                zIndex: 2,
                '&.Mui-focused': {
                  display: 'none'
                },
                '&.MuiFormLabel-filled': {
                  display: 'none'
                }
              },
              '.MuiInput-root': {
                padding: '8px 16px 8px 50px',
                height: '100%',
                minHeight: '58px',
                margin: 0,
                // borderRadius: '4px',
                backgroundColor: 'var(--ba-gray-100)',
                // boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
                '&:hover': {
                  backgroundColor: 'var(--ba-gray-100)'
                  // boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)'
                },
                '&.Mui-focused': {
                  // outline: '2px solid var(--ba-blue-200)'
                },
                '&.Mui-error': {
                  color: 'var(--primary-shade)'
                },
                '.MuiInputBase-input': {
                  outline: 'none !important'
                }
              }
            },
            '.MuiInputLabel-standard': {
              color: 'var(--ba-gray-425)'
            },
            '.app-icon-field': {
              flex: 'grow',
              height: '100%',
              minHeight: '58px',
              width: '100%'
            }
          },
          '&.search-iconless-field': {
            boxShadow: 'none',
            backgroundColor: 'var(--white)',
            '&.standardInputField': {
              '.MuiInputLabel-standard': {
                left: '49px',
                top: '-4px',
                zIndex: 2,
                '&.Mui-focused': {
                  display: 'none'
                },
                '&.MuiFormLabel-filled': {
                  display: 'none'
                }
              },
              '.MuiInput-root': {
                padding: '8px 16px',
                height: '100%',
                width: '100%',
                minHeight: '58px',
                margin: 0,
                backgroundColor: 'var(--white)',
                '&.Mui-focused': {
                  outline: '2px solid var(--ba-blue-200)'
                },
                '&.Mui-error': {
                  color: 'var(--primary-shade)'
                },
                '.MuiInputBase-input': {
                  outline: 'none !important'
                }
              },
              '.MuiTextField-root': {
                width: '100%',
                '&:focus-visible': {
                  outline: '2px solid var(--black) !important',
                  outlineOffset: '-2px'
                }
                // '&:hover': {
                //   boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)'
                // }
              }
            }
          },
          '&.skip-links': {
            marginLeft: 0,
            marginTop: '9px',
            '.MuiFormLabel-root': {
              fontSize: 'var(--t-14-15)',
              top: '-12px',
              '&.Mui-focused': {
                top: '12px',
                color: 'var(--ba-primary-black)',
                fontSize: 'var(--t-18-19)'
              }
            },

            '.MuiInputBase-root': {
              border: '1px solid var(--ba-primary-black)',

              '.MuiSelect-select': {
                padding: 0,
                padingLeft: '40px'
              },
              '.MuiOutlinedInput-notchedOutline': {
                display: 'none'
              }
            }
          }
        }
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          '&.app-dropdown-popover': {
            position: 'absolute !important',
            top: '48px !important',
            minWidth: '150px',
            maxWidth: '350px',

            '.MuiPaper-root': {
              maxWidth: '100%',
              width: '100%',
              boxShadow: 'none'
            }
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.app-dropdown-list': {
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.20)',
            maxWidth: '100%',
            width: '100%'
          },
          '&.app-menu-list': {
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.20)',
            minWidth: 300,
            border: 'none',
            borderRadius: 4,
            paddingTop: '16px',
            paddingBottom: '16px'
          },
          '.app-dropdown-items': {
            padding: '10px 16px',
            gap: 12,
            fontSize: 'var(--t-15)',
            fontWeight: '400',
            lineHeight: '24px',
            maxWidth: '100%',
            wordBreak: 'break-word'
          },
          '.app-menu-list-items': {
            padding: '12px 16px',
            fontSize: 'var(--t-15)',
            fontWeight: '400',
            lineHeight: '24px',
            maxWidth: '100%',
            textTransform: 'none !important',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            '&:focus': {
              outline: 'none !important'
            },
            '&:focus-visible': {
              outline: '2px solid var(--black) !important',
              outlineOffset: '-2px'
            }
          },
          '&.app-nav-list': {
            columnGap: 24,
            '.MuiListItem-root': {
              borderBottom: '1px solid var(--ba-gray-200)',
              padding: '16px 0',
              lineHeight: '24px',
              fontSize: 17,
              fontWeight: 600
            }
          },
          '&.MuiList-root': {
            '.MuiMenuItem-root': {
              '&.Mui-selected': {
                backgroundColor: 'var(--ba-gray-100)',
                '&:hover': {
                  backgroundColor: 'var(--ba-gray-100)'
                }
              }
            }
          },
          '.skip-link-list-items': {
            fontSize: 'var(--t-15)'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          '&.search-input-label': {
            color: 'var(--ba-gray-900)',
            left: 12,
            top: '-8px',
            '&.Mui-focused': {
              top: '0px'
            },
            '&.MuiFormLabel-filled': {
              top: '0px'
            }
          },
          '&.select-input-label': {
            color: 'var(--ba-gray-425)',
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '24px',
            left: '16px',
            top: '-3px',
            zIndex: 2,
            pointerEvent: 'none',
            '&:hover': {
              color: 'var(--ba-primary-black)'
            },
            '&.white-select-label': {
              '&.Mui-focused': {
                top: 12
              },
              '&.MuiFormLabel-filled': {
                top: 12
              },
              '&.label-outline': {
                top: -11,
                color: 'var(--ba-primary-black)',
                '&.Mui-focused': {
                  top: '13px',
                  fontSize: '21px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  color: 'var(--white)'
                }
              }
            },
            '&.Mui-focused, &.MuiFormLabel-filled': {
              color: 'var(--ba-gray-800)',
              lineHeight: '18px'
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.app-search-form-select': {
            marginTop: 0,
            paddingTop: 16,
            paddingLeft: 12,

            '&.Mui-focused': {
              marginTop: 16,
              paddingTop: 0
            },
            '&:before, &:not(.Mui-disabled, .Mui-error):before': {
              borderColor: 'transparent'
            }
          },
          '&.app-form-select': {
            marginTop: 0,
            paddingTop: 26,
            paddingLeft: 16,
            paddingRight: 16,
            fontSize: 'var(--t-15)',
            fontWeight: 500,
            lineHeight: '24px',
            borderRadius: 4,
            backgroundColor: 'var(--ba-gray-100)',
            '.MuiInputLabel-root.select-input-label': {
              color: 'var(--ba-gray-425)'
            },

            '.MuiSelect-select:focus': {
              backgroundColor: 'transparent'
            },

            '&.Mui-focused': {
              marginTop: 0,
              paddingTop: 26,
              backgroundColor: 'var(--ba-gray-100)',
              outline: '2px solid var(--ba-blue-200)'
            },
            '&.MuiSelect-filled': {
              fontSize: '15px',
              fontWeight: 500,
              lineHeight: '24px',
              marginTop: 0,
              paddingTop: 0
            },
            '&:hover': {
              backgroundColor: palette
                ? palette['ba-field-focus-bg']
                : '#EAEAEA'
            },
            '&:before, &:after, &:not(.Mui-disabled, .Mui-error):before': {
              borderColor: 'transparent'
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.app-country-autoselect': {
            '.MuiTextField-root': {
              '.MuiOutlinedInput-root': {
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'solid 1px transparent'
                }
              }
            }
          },
          // '&.MuiAutocomplete-option': {},
          // '&.Mui-focused': {},
          '&.app-find-care-autocomplete': {
            '.MuiInputLabel-root': {
              fontSize: 'var(--t-15)',
              top: '-7px',
              '&.Mui-focused': {
                top: '0px'
              },
              '&.MuiFormLabel-filled': {
                top: '0px'
              }
            },
            '.MuiInputBase-root': {
              '&:before': {
                borderBottom: 'solid 1px transparent'
              },
              '&:after': {
                borderBottom: 'solid 2px transparent'
              },
              'MuiInputBase-input': {
                '&.MuiInput-input': {
                  paddingLeft: '4px'
                }
              }
            }
          }
        },
        popper: {
          zIndex: 999999999999
        },
        option: {
          fontSize: 'var(--t-15)',
          fontWeight: '400',
          lineHeight: '24px',
          padding: '12px 16px',
          '&[aria-selected="true"]': {
            backgroundColor: '#e3abed'
          },

          '&:hover': {
            backgroundColor: '#9c27b0'
          },
          backgroundColor: 'var(--white)',

          '&.location-option': {
            fontSize: 'var(--t-15)',
            fontWeight: '500',
            paddingLeft: '8px'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          '.MuiTabs-flexContainer': {
            padding: 8,
            backgroundColor: 'var(--ba-gray-100)',
            borderRadius: '4px',
            maxWidth: '100%',
            minWidth: 0,

            '&.MuiTabs-centered': {
              display: 'inline-flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              overflowX: 'auto',
              maxWidth: '100%',
              minWidth: 0,
              margin: 'auto'
            }
          },

          '.MuiTabs-indicator': {
            display: 'none'
          },
          '&.tabs-no-border': {
            '.MuiTabs-flexContainer': {
              display: 'flex',
              overflow: 'auto',
              maxWidth: '96%',
              gap: 50,
              padding: '2px',
              backgroundColor: 'transparent',
              '.Mui-selected': {
                backgroundColor: 'transparent'
              },
              '.MuiTab-root': {
                padding: '8px 4px 8px 0px'
              }
            },
            '.MuiTabs-indicator': {
              display: 'none'
            }
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            outline: 'none !important',
            border: '0 none',
            fontSize: 'var(--t-15)',
            fontWeight: 500,
            color: 'var(--ba-primary-black)',
            lineHeight: 1.6,
            textTransform: 'none',
            padding: '8px 16px',
            minHeight: '40px',
            position: 'relative',
            transition: 'color 0.2s ease-in-out, background 0.2s ease-in-out',
            willChange: 'color, background',
            '&:hover': {
              border: 0,
              color: 'var(--ba-primary-red)'
            },
            '&:focus-visible': {
              outline: '2px solid var(--black) !important',
              zIndex: 2
            },
            img: {
              width: '22px !important',
              height: '22px !important'
            }
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--ba-primary-red)',
            color: 'var(--white)',
            borderRadius: '4px',
            border: 'none',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.20)',
            '&:hover': {
              color: 'var(--white)',
              cursor: 'normal'
            }
          },
          '&.tab-no-border': {
            // '&.MuiTab-root': {
            border: '0 none',
            fontSize: 'var(--t-18-21)',
            fontWeight: 700,
            color: 'var(--ba-gray-900)',
            lineHeight: 1.6,
            textTransform: 'none',
            padding: '8px 0',
            minHeight: '40px',
            outline: 'none !important',

            '&:hover': {
              border: 0
            },
            '&:focus-visible': {
              outline: '2px solid var(--black) !important'
            },
            // },
            '&.Mui-selected': {
              color: 'var(--black)',
              border: 'none',
              boxShadow: 'none'
            }
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          transition: 'color 0.3s ease-in-out',
          willChange: 'color',
          color: 'var(--primary-shade)',
          '&:hover': {
            color: 'var(--primary-shade)',
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          borderBottom: 'rgba(var(--primary-black),0.30) 1px solid',
          borderRadius: '0 !important',
          padding: 0,

          '.MuiAccordionSummary-root': {
            outline: 'none !important',
            position: 'relative',
            padding: '1.25rem 0',
            minHeight: 0,
            alignItems: 'flex-start',

            '.MuiAccordionSummary-content': {
              margin: '0',

              h6: {
                color: 'var(--ba-primary-black)'
              }
            },
            '&.Mui-expanded': {
              minHeight: 'inherit',
              paddingBottom: '0.5rem',
              '.MuiAccordionSummary-content': {
                h6: {
                  color: 'var(--ba-ba-primary-black)'
                }
              }
            },
            '&:focus-visible': {
              outline: '2px solid var(--black) !important',
              zIndex: 2
            }
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            color: 'var(--black)'
          },
          '.MuiCollapse-root': {
            '.MuiAccordionDetails-root': {
              paddingLeft: '0',
              paddingRight: '0',
              paddingTop: '0',
              paddingBottom: '1.25rem '
            }
          },
          '&.grey-shade': {
            marginBottom: '0.5rem',
            padding: 0,
            backgroundColor: 'rgba(var(--primary-black),0.06)',
            borderBottom: 'none',

            '&.Mui-expanded': {
              marginBottom: '0.5rem !important'
            },
            '.MuiCollapse-root': {
              padding: '0 1.25rem',
              '.MuiAccordionDetails-root': {
                padding: '0 38px',
                paddingBottom: '1.25rem'
              }
            },
            '.MuiAccordionSummary-root': {
              boxShadow: 'none',
              padding: '1.25rem 1.25rem',
              minHeight: 0,
              outline: 'none !important',
              position: 'relative',
              alignItems: 'flex-start',
              '&:focus-visible': {
                outline: '2px solid var(--black) !important',
                zIndex: 2,
                backgroundColor: 'transparent'
              },

              '&.Mui-expanded': {
                marginBottom: 0,
                minHeight: 0,
                paddingBottom: '8px',
                '.MuiAccordionSummary-content': {
                  margin: 0
                }
              },

              '&.MuiPaper-root': {
                boxShadow: 'none'
              },
              '&:not(.active-item):hover': {
                opacity: 0.8
              }
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.service-checkbox': {
            '&.MuiCheckbox-root': {
              padding: '0px',
              alignItems: 'start',
              height: 'fit-content',
              marginRight: '10px',
              outline: '1px solid transparent',
              '&:hover, &:focus-visible, &:focus-within': {
                outline: '1px solid var(--ba-green-450)'
              },

              '.MuiTouchRipple-root': {
                display: 'none',
                width: '0',
                height: '0'
              }
            },
            '&.Mui-checked': {
              color: '#FFFFFF',
              paddingBottom: '0px',
              paddingLeft: '0px'
            }
          }
        }
      }
    },
    // radio button group
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&.service-radio': {
            '.MuiRadio-root': {
              color: '#FFFFFF',
              padding: '0px',
              paddingTop: '0px',
              marginRight: '10px',
              border: '1px solid transparent',
              tabIndex: '0',
              '&:hover': {
                backgroundColor: 'transparent',
                border: '1px solid var(--ba-green-450)'
              },
              '&:focus-visible, &:focus-within': {
                border: '1px solid var(--ba-green-450)'
              }
            },
            '.MuiFormControlLabel-root': {
              marginBottom: '16px',
              tabIndex: '0',
              '&:focus-visible': {
                outline: '1px solid var(--ba-green-450)'
              }
            },
            '.MuiFormControlLabel-label': {
              fontSize: '13px !important',
              fontWeight: 'normal'
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.rounded-none': {
            '&.Mui-focusVisible': {
              outline: '1px solid var(--ba-green-450)'
            },
            '&:hover': {
              outline: '1px solid var(--ba-green-450)'
            }
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          overscrollBehavior: 'contain'
        }
      }
    }
  },
  typography: {
    fontFamily: [
      "'Inter', sans-serif",
      "'frutiger', sans-serif",
      "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
    ].join(','),
    fontSize: 16
  },
  breakpoints: {
    values: styleBreakPoints
  }
});
